<template>
  <div id="PaineisSTContainer" class="container-fundo" v-bind:style="{ 'background-color':COR_PRINCIPAL }">

    <!-- <pre>{{ tree_node }}</pre> -->
        <!-- Título da página -->
        <v-row style="height: 59px;"> 
        <v-col cols="1">
          <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2 d-flex">
          <v-toolbar-title class="text-white title-page ml-4 mt-2">
            <!-- <router-link
              :to="{ name: '' }"
              class="nav-link"
              aria-current="page"
              >
              <v-btn icon dark color="grey" class="btn-icon mr-1">
                <v-icon color="#84A0CD" class="icon-action"> mdi-chevron-left </v-icon>
              </v-btn>
            </router-link>  -->
            Painel
          </v-toolbar-title>
        </v-toolbar-title>
        </v-col>
        <v-col class="text-center d-flex flex-column align-center justify-center">
          <v-select
              :items="menu_config[0].children"
              v-model="selected"
              item-value="cod_painel"
              label="painel"
              filled
              dense
              required
              flat
              solo-inverted
              class="search-input mt-1"
              dark
              tabindex="-1"
              return-object
              style="max-width: 450px;"
            >
            <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              {{ data.item.menu_nivel1 }} - {{ data.item.nome }}
            </template>            
            <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.menu_nivel1 }} - {{ data.item.nome }}
            </template>
          </v-select>
        </v-col>
      </v-row>

      <!-- PAINEL ------------------------------------>
        <div
          v-if="tree_node"
          class="mt-3"
          style="background-color: #F3F3F3">
          <PaineisSTComponente
            ref="PaineisSTComponente"
            :Painel="tree_node"
            :detalhes_="false"
          />
          <!-- FIM PAINEL ------------------------------------>
        </div>    

  </div>
</template>

<script>
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO } from "../../../services/constantes";
import { EventBus } from "../../../services/eventbus";
import store_site from "../../../store/store_site";
import { groupArrayMultKeys } from "../../../services/funcoes";
import store_usuario from "../../../store/store_usuario"
import { Painel, ListaPaineisUsuarioGet } from "../../../services/global"
import PaineisSTComponente from './PaineisSTComponente.vue';

export default {
  name: "PaineisSTContainer",

  props: ['MENU_NIVEL1'],

  components: {
    PaineisSTComponente
  },

  data() {

    return { 

      store_site              : store_site,

      groupArrayMultKeys      : groupArrayMultKeys,
      
      COR_PRINCIPAL       : COR_PRINCIPAL,
      COR_SUBTITULO       : COR_SUBTITULO,
      COR_SECUNDARIA      : COR_SECUNDARIA,

      tree                : [],
      tree_node           : null,
      selected            : null,
      menu_config         : [{children: []}],

    }
  },

  created() {
		// EventBus.$on('lista_paineis', () => {
		//   this.lista_paineis(this.MENU_NIVEL1);
		// })
  },

  mounted() {
    // Busca Lista de Paines e já atribui o painel padrão que o usuário deixou gravado
    this.lista_paineis_usuario(this.MENU_NIVEL1);
  },

  computed: {
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },
  },

  watch: {
    // Abre o relatório toda vez que altera a seleção para outro
    selected(val) {
      if (val && val.cod_painel) {
        this.abrePainelExterno2(val);
      }
    }
  },

  methods: {

    // Consulta os Paineis para o usuario especifico
    async lista_paineis_usuario(menu_nivel1) {
      var la_Res = [];
      la_Res = await ListaPaineisUsuarioGet();
      const la_Agrupado = groupArrayMultKeys(la_Res, ['menu_nivel1'])
      this.menu_config = []
      Object.entries(la_Agrupado).forEach((elem) => {
        if (elem[0] == menu_nivel1) {
          this.selected = elem[1][0]
          this.menu_config.push({ cod_painel : elem[0],
                                  titulo     : elem[0],
                                  children   : elem[1]}
                               )
        }
      })
    },

    // Abre o Painel Selecionado
    async abrePainelExterno2(selected) {
      if (selected && selected.cod_painel) {
        this.tree_node = null;
          let body = {
            cod_painel: selected.cod_painel,
            tipo: "json_conteudo",
            parametros: [{ Name: "cod_usuario", Value: store_usuario.user.cod_usuario }],
          };
          let lo_Rel = await Painel(body);
          this.tree_node = {  cod_painel    : selected.cod_painel,
                              nome          : selected.nome,
                              titulo        : selected.titulo,
                              descricao     : selected.descricao,
                              conteudo      : lo_Rel,
                              situacao      : selected.situacao,
                              menu_nivel1   : selected.menu_nivel1,
                              menu_nivel2   : selected.menu_nivel2
                          };
      }
    },

  }

};
</script>

<style scoped>
.title-page {
  font-family: sans-serif;
  letter-spacing: 0px;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.container-fundo {
  left: 0px !important
}

</style>

