<!--
	Relatorio Base Stimulsoft
	Esta aplicacao abre o editor na tela de relatorios manutenção, quando o usuário clica no list e escolhe o relatório
-->
<template>
		<div id="viewerContent"></div>
</template>

<script>

var viewer      = null;
var designer    = null;

import store_site from "../../../store/store_site";
import { baseURL } from "../../../services/API";

export default {
  name : "PaineisSTComponente",

  components: {
  },

  props: ['Painel', 'detalhes_'],

  data() {
    return {
    	store_site        	: store_site,

			dialog_duplicar	  	: false,
			dados_duplicar			: null,
    }
  },

  computed: {
    painel: function() {
       	return this.Painel.conteudo;
    },
  },

  destroyed() {

  },

  created() {

    // console.log('Loading Viewer view');
    
    // console.log('Creating the dashboard viewer with default options');
    // var viewer = new window.Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);

    // console.log('Creating a new dashboard instance');
    
    // var report = window.Stimulsoft.Report.StiReport.createNewDashboard();

    // console.log('Load dashboard from url');
    // //report.loadFile('/dashboard/DashboardChristmas.mrt');
    // report.load(this.Painel.conteudo);

    // console.log('Assigning dashboard to the viewer, the dashboard will be built automatically after rendering the viewer');
    // viewer.report = report;

    // console.log('Rendering the viewer to selected element');
    // viewer.renderHtml('viewerContent');

    // console.log('Loading completed successfully!');

  },

	mounted() {
    this.createViewer();
    this.setDashboard(null);
  },

  methods: {

    seta_dialog(rel) {
      this.dados_duplicar = rel;
      this.dialog_duplicar = true;
    },

    createViewer() {
			// Specify necessary options for the viewer
			var options = new window.Stimulsoft.Viewer.StiViewerOptions();
      options.width = "100%";
			options.appearance.fullScreenMode = false
	 		options.appearance.interfaceType = 0
	 		options.appearance.parametersPanelDateFormat = "dd/MM/YYYY"
 			options.appearance.showTooltips = true
 			options.appearance.showTooltipsHelp = false
			//options.toolbar.showAboutButton 	= false;
			options.toolbar.showOpenButton		= false;
			
			// O options.toolbar desta forma dá erro na versão 2023, os elementos tem de ser um a um como descritos abaixo
			// options.toolbar	= 	{ elementos }
			options.toolbar.showDesignButton 		= false;
			options.toolbar.showAboutButton 		= false;
			options.toolbar.ShowSendEmailButton = true;

			// Create an instance of the viewer
			viewer = new window.Stimulsoft.Viewer.StiViewer(options, "StiViewer", false);
			viewer.renderHtml("viewerContent");
		},

		setDashboard(dashboardObject) {
			// Forcibly show process indicator
			viewer.showProcessIndicator();
			//var Painel_ =  this.Painel
			//this.Painel.conteudo
			dashboardObject = this.Painel.conteudo;

			// Timeout need for immediate display loading report indicator
			setTimeout(function () {
				// Create a new report instance
				var dashboard = window.Stimulsoft.Report.StiReport.createNewDashboard();
				// Load reports from JSON object
				dashboard.load(dashboardObject);

				// Assign the dashboard to the viewer
				viewer.report = dashboard;
				viewer.renderHtml("viewerContent");
			}, 50);
		},

		// Set report button style
		setReportButton(button) {
			for (var reportName in reportsCollection) {
				var reportButton = document.getElementById(reportName);
				reportButton.isSelected = reportButton == button;
				reportButton.className = reportButton.isSelected ? "ReportButton Selected" : "ReportButton";
			}
		},

		// Load first report after the page is loaded
		// onBodyLoad() {
		// 	createViewer();
		// 	selectedButton = document.getElementById("Relatorio_31");
		// 	//console.log(selectedButton);
		// 	onButtonClick(selectedButton, Relatorio_31);
		// },

		// Demo Report button events
		// ---

		onButtonClick(button, dashboardObject) {
			selectedButton.className = "ReportButton";
			button.className = "ReportButton Selected";
			selectedButton = button;
			setDashboard(dashboardObject);
		},

		onButtonMouseOver(button) {
			if (button != selectedButton) button.className = "ReportButton Over";
		},

		onButtonMouseOut(button) {
			if (button != selectedButton) button.className = "ReportButton";
		}

  }

}

</script>

<style scoped>

#viewerContent {
		height: calc(100vh - 125px);
		width: calc(100vw - 20px);
		padding-left: 10px;
		padding-right: 10px;
		/* width: 100% !important; */
		/* height: 100% !important; */
		background-color:#F3F3F3;
	}

</style>